:root {
  --vh: 100vh;
  --vw: 100vw;
  --wrapper-width: 100vw;
  --content-width: 1075px;
  --wrapper-padding: 48px;
  --block-padding: 42px;
  --font-color: #333;
}

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400&display=swap');


html {
  scroll-behavior: smooth;
}

.App {
  text-align: left;
  color: #333;
  color: var(--font-color);
  font-family: "Work Sans", "Helvetica neue", "Helvetica", sans-serif;
  letter-spacing: -0.02em;
  font-weight: 400;
  opacity: 1;
}

#fullPage {
  background-color: #fff;
}

a {
  color: #333;
  text-decoration: none;
  transition: 0.3s;
}

a:hover {
  color: #666;
  text-decoration: none;
}

.anchor {
  white-space: normal;
}

.wrapper {
  color: #333;
  max-width: var(--wrapper-width);
  margin: auto;
  padding: var(--wrapper-padding);
  margin-bottom: 0;
}

#preload {
  display: none;
}

.home {
  background-color: #fff;
}

.home .wrapper {
  position: relative;
  color: #fff;
  background-color: #fff;
  padding: 0px ;
  height: calc(var(--vh) / 1);
  overflow: hidden;
}

.tint {
  position: absolute;
  /*background-image: url("assets/banner13.jpg");*/
  background-size: cover;
  background-position: center left;
  background-repeat: no-repeat;
  width: var(--wrapper-width);
  height: calc(var(--vh) / 1 );
  margin: 0 auto auto auto;
  opacity: 1;
  z-index: 1;
  transition: 0.3s;
}

.wrapperSqueeze {
  max-width: 1075px;
  /*min-height: calc(100vh - 96px);*/
  margin: auto;
  padding: 0 var(--wrapper-padding) 0 var(--wrapper-padding);
  opacity: 0;
  animation: fade-in forwards 0.8s ease-in-out;
  animation-delay: 0s;
  min-height: calc(var(--vh) - 330px - (var(--wrapper-padding)*2));
}

.wrapperFull {
  width: 100vw;
  /*min-height: calc(100vh - 96px);*/
  margin: auto;
  padding: 0 var(--wrapper-padding) 0 var(--wrapper-padding);
  opacity: 0;
  animation: fade-in forwards 0.8s ease-in-out;
  animation-delay: 0s;
  min-height: calc(var(--vh) - 330px - (var(--wrapper-padding)*2));
}

/********/
/* MENU */
/********/

nav.menuTop ul {
  position: absolute;
  top: 44px;
  right: calc(var(--wrapper-padding)*1);
  margin: 0px;
  transform: translate(12px, 0px);
  list-style: none;
  text-align: right;
  z-index: 100;
  letter-spacing: -0.02em;
}

nav.menuTop ul li {
  display: inline;
  opacity: 0;
}

nav.menuTop ul li a {
  display: inline;
  color: #333;
  font-size: 16px;
  font-weight: 600;
  transition: 0.3s;
  margin: 0 0px 0 24px;
  font-weight: 600;
  border-radius: 10px;
  border: solid 1px transparent;
  padding: 11px;
  transition: ease-in-out 0.3s;
}

nav.menuTop ul li div.langSel {
  display: inline;
  color: #333;
  font-size: 12px;
  font-weight: 600;
  transition: 0.3s;
  margin: 0 0px 0 24px;
  font-weight: 600;
  border-radius: 10px;
  padding: 8px;
  border: solid 1px #333;
  transition: ease-in-out 0.3s;
}

.home nav.menuTop ul li div.langSel {
  border: solid 1px #333;
}

nav.menuTop ul li a.active {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  background: #333;
  border-radius: 10px;
  padding: 11px;
}

.home nav.menuTop ul li a, .home nav.menuTop ul li div.langSel {
  color: #333;
  /*text-shadow: 0px 0px 3px rgba(0,0,0,0.2);*/
}

.home nav.menuTop ul li a:hover, .home nav.menuTop ul li div.langSel:hover {
  background: transparent;
  color: #fff;
}

nav.menuTop ul li a:hover, nav.menuTop ul li div.langSel:hover {
  background: transparent;
  color: #333 !important;
  border: solid 1px #333;
  border-radius: 10px;
}

nav.menuTop ul li:nth-child(1) {
  animation: fade-in forwards 0.8s ease-in-out;
  animation-delay: 0.6s;
}

nav.menuTop ul li:nth-child(2) {
  animation: fade-in forwards 0.8s ease-in-out;
  animation-delay: 0.9s;
}

nav.menuTop ul li:nth-child(3) {
  animation: fade-in forwards 0.8s ease-in-out;
  animation-delay: 1.2s;
}

nav.menuTop ul li:nth-child(4) {
  animation: fade-in forwards 0.8s ease-in-out;
  animation-delay: 1.5s;
}

nav.menuTop ul li:nth-child(5) {
  animation: fade-in forwards 0.8s ease-in-out;
  animation-delay: 1.8s;
}

nav.menuTop ul li:nth-child(6) {
  animation: fade-in forwards 0.8s ease-in-out;
  animation-delay: 2.1s;
}

nav.menuTop .langSel {
  cursor: pointer;
}

.btnHamburger {
  cursor: pointer;
  display: none;
}

.home .btnHamburger {
  color: #333;
  font-size: 32px;
}

#logo {
  font-size: 16px;
  position: absolute;
  top: 30px;
  left: var(--wrapper-padding);
  width: auto;
  z-index: 10000;
}

#logo a {
  font-family:  Times, "Times New Roman", serif;
  font-weight: 800;
  font-size: 32px;
  padding-right: 12px;
  color: inherit;
  line-height: 1;
}

.home #logo {
  top: calc(var(--vh) /2 - 130px);
  left: 0px;
  width: calc(var(--vw) );
  text-align: center;
  opacity: 1;
}

.home #logo a {
  font-family:  Times, "Times New Roman", serif;
  font-weight: 400;
  font-size: 120px !important;
  padding-right: 12px;
  color: #333;
  animation: fade-in-slide-down forwards 0.6s ease-in-out;
}

.home #logo a span {
  font-size: 120px !important;;
  color: #333;
  border-bottom: solid 0px #333;
}

.home #logo .bar {
}

.home #logo a b {
  font-weight: normal;
  transition: 0.3s;
}

/*********/
/* QUOTE */
/*********/

.headerQuote {
  position: absolute;
  bottom: calc(var(--wrapper-padding)*1);
  z-index: 100;
  font-family: "Work Sans", "Helvetica neue", "Helvetica", sans-serif;
  letter-spacing: 0px;
  font-weight: 700;
  font-size: 24px;
  width: var(--wrapper-width);
  color: #fff;
  line-height: 1.2;
}

.headerQuote div.text {
  opacity: 0;
  display: flexbox;
  animation: fade-in-slide forwards 1s ease-in-out;
  animation-delay: 0.3s;
  font-family: "Work Sans", "Helvetica neue", "Helvetica", sans-serif;
  font-weight: 700;
  margin: 0px;
  padding: 0px;
  max-width: 480px;
  margin: auto 0 auto calc(var(--wrapper-padding)*1);
  text-align: left;
  line-height: 1.2;
  padding: 0.5em 0 0.5em 1.3em;
  /*text-shadow: 1px 1px 20px rgba(0,0,0,0.8);*/
  text-indent: -0.5em;
  border-left: 9px solid rgba(255,255,255,0.1);
}

.myQuoteStrike {
  /*text-decoration: line-through;
  color: #7E5563;*/
}

.headerQuote div.text span {
  transition: 1s;
}

.headerQuote div.by {
  display: flexbox;
  letter-spacing: 0px;
  text-indent: 0;
  font-size: 16px;
  font-family: Georgia, "Times", serif;
  font-weight: 500;
  opacity: 0;
  animation: fade-in forwards 0.8s ease-in;
  animation-delay: 0.5s;
  display: block;
  padding: 16px 0 0 0;
  font-style: italic;
  text-shadow: 0px 0px 10px rgba(0,0,0,0.8);
}

.headerImage {
  width: 100%;
  opacity: 0;
}

.fadeOut {
  animation: fade-out 0.6s forwards;
}


/***********/
/* GENERAL */
/***********/

.anchor {
  padding: 0;
  font-size: 62px;
  line-height: 72px !important;
  font-family:  Times, "Times New Roman", serif;
  font-weight: 600;
  text-align: left;
  width: auto;
  color: #333;
  user-select: none;
}

.anchor u {
	text-decoration: none;
	/*box-shadow: inset 0 0px 0 white, inset 0 -18px 0 #f5f5f5;*/
}

.anchor u.flash {
	text-decoration: none;
	/*box-shadow: inset 0px 0px 0 white, inset 0 -18px 0 #f5f5f5;*/
}

blockquote {
  border-left: 6px solid #eee;
  margin: 0px;
  padding: 3px 0 6px 24px;
}

.spacer {
  width: 0px;
  height: calc(var(--wrapper-padding)*1);
}

.spacerHalf {
  height: calc(var(--wrapper-padding) /2);
}

.sectionTitle {
  display: flexbox;
  position: relative;
  max-width: var(--content-width);
  margin-bottom: calc(var(--wrapper-padding));
}

/*****************/
/* ABOUT SECTION */
/*****************/

.about {
  padding: calc(var(--block-padding)*3) 0 calc(var(--block-padding)*1) 0;
}

.home .about {
  padding:  calc(var(--block-padding)*3) 0 calc(var(--block-padding)*1) 0;
}

.about .anchor {
  padding-right: 30px;
}

.about h1 {
  color: #64434E;
  font-size: 36px;
  line-height: 54px;
  font-family: "Work Sans", "Helvetica neue", "Helvetica", sans-serif;
  font-weight: 700;
}

.about .readMore  {
  position: absolute;
  transform: translate(0px, -86px);
  font-size: 14px;
  padding: 16px;
  line-height: 16px;
  border-radius: 6px;
  font-weight: 600;
  background-color: #7E5563;
  color: #fff;
  transition: ease-in-out 0.3s;
  cursor: pointer;
}

.about a.readMore:hover {
  background-color: #333;
  color: #fff;
}

.about p.end {
  transform: translate(0px, -96px);
  height: 70px;
  background: linear-gradient(to top, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.6) 80%,rgba(255,255,255,0) 100%); 
}

.about .authorBubble {
  margin-top: 28px !important;
}

/*******************/
/* CLIENTS SECTION */
/*******************/

.home .clientBlock {
  padding: calc(var(--block-padding)*3) 0 calc(var(--block-padding)*0) 0;
}

.clients .blockFull {
  flex-direction: column;
}

.clients .blockFull .blockText {
  min-width: 100% !important;
  text-align: justify;
  margin-top: 0px;
  color: #333;
}

.clients .blockFull .blockText p {
  min-width: 100% !important;
  text-align: justify;
  color: #333;
}

.clients p {
  font-size: 20px;
  line-height: 1.8;
  color: #333 !important;
  text-transform: uppercase;
  letter-spacing: -1px;
}

.clients p strong {
  color: #666;
}


/*******************/
/* BLOCK 2 COLUMNS */
/*******************/

.blockFull {
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  max-width: 100%;
}

.blockLeft {
  display: flexbox;
  position: relative;
  padding: 0px 0px var(--block-padding) 0px;
  max-width: 500px;
  white-space: nowrap;
}

.blockLeft .authorBubble {
  padding-top: 7px;
}

.blockText {
  display: flexbox;
  margin-top: 9px;
  max-width: 100%;
  padding: 10px 0 0 0;
  border-left: solid 0px #eee;
  font-size: 21px;
  line-height: 36px;
  color: #333;
}

.blockText p {
  display: block;
  max-width: 520px;
}

.blockText p a {
  color: #333;
  border-bottom: solid 2px #333;
  transition: ease-in-out 0.3s;
}

.blockText p a:hover {
  color: #666;
  border-bottom: solid 2px #666;
}

.blockText h2 {
  color: #333;
  font-size: 36px;
  line-height: 48px;
  font-family: "Work Sans", "Helvetica neue", "Helvetica", sans-serif;
  font-weight: 700;
  margin: 0px;
}

.blockText li {
  padding: 0 0 14px 0;
}

.blockText p:first-child {
  margin-top: 0px;
}

.blockText p img {
  width: 100%;
}

.blockText .image .loading {
  position: relative;
  width: 100%;
  height: 320px;
  background-color: #f5f5f5;
  margin: 10px 0 36px 0;
}

.blockLeft .image .loading {
  position: relative;
  width: 510px;
  height: 590px;
  background-color: #f5f5f5;
  margin: 48px 48px 48px 0;
}

/****************/
/* TESTIMONIAL  */
/****************/

.testimonials {
  position: relative;
  margin-top: 60px;
}

.testimonials .navigation {
  position: absolute;
  display: flex;
  transform: translate(0px, 5px);
  right: 74px;
  z-index: 100;
}

.testimonials .navigation .dot {
  font-size: 50px;
  color: #ccc;
  display: flex;
  cursor: pointer;
  letter-spacing: -3px;
  transition: 0.6s;
}

.testimonials .navigation .dot.active {
  font-size: 50px;
  color: #ee5b4f;
  display: flex;
}

.testimonials .quoteSign {
  font-family: "Times", 'Times New Roman', serif;
  font-style: italic;
  color: #333;
  position: absolute;
  content: "“";
  transform: translate(10px, 28px);
  font-size: 200px;
  z-index: 0;
}

.testimonials .testimonial {
  position: absolute;
  margin: 0px 60px 40px 0;
  max-width: 100%;
  opacity: 0;
}

.testimonials .testimonial:nth-child(2) {
  animation: fade-in-slide 0.9s forwards;
}

.testimonials .testimonial .loading {
  margin: 10px 0 0 0;
  position: absolute;
  width: 42px;
  height: 42px;
  right: 0px;
  border-radius: 100%;
  border: solid #ee5b4f 4px;
}

.testimonials .testimonial .loading div {
  border-radius: 100%;
  overflow: hidden;
}

.testimonials .testimonial .byLine {
  font-size: 14px;
  position: absolute;
  right: 50px;
  text-align: right;
  transform: translate(-8px, 16px);
  font-weight: 600;
}

.testimonials .testimonial.myFadeIn {
  animation: fade-in-slide 0.9s forwards;
}

.testimonials .testimonial.myFadeOut {
  animation: fade-out-slide 0.3s forwards;
}

.testimonials .testimonial blockquote {
  font-family: Times, 'Times New Roman', serif;
  font-size: 20px;
  line-height: 32px;
  position: relative;
  color: #333;
  background-color: #f1f1f1;
  border: none;
  padding: 40px 56px 40px 60px;
  margin: 0px 0 0px 0;
  font-style: italic;
  border-radius: 20px;
  border-bottom-right-radius: 3px;
  white-space: normal;
}

.testimonials .testimonial blockquote strong {
  font-size: 18px;
}


/*********************/
/* TESTIMONIALS HOME */
/*********************/

.home .testimonials {
  position: absolute !important;
  right: 0px;
  bottom: 72px;
  width: 600px;
  z-index: 3000;
  width: 100%;
}

.home .testimonials .navigation {
  width: 100% !important;
  text-align: center !important;
  right: 0px !important;
  bottom: -52px;
  transform: none !important;
  justify-content: center !important;
}

.home .testimonials .testimonial {
  position: absolute;
  bottom: 0px;
  margin: 0px 60px 40px 0;
  width: 100%;
  opacity: 0;
}

.home .testimonials .myContent {
  position: relative;
  width: 600px !important;
  margin: auto;
}

.home .testimonials .navigation .dot {
  color: rgba(0,0,0,0.09);
}

.home .testimonials .navigation .dot.active {
  color: #333;
}

.home .testimonials .testimonial .content {
  margin: auto !important;
  width: 700px !important;
}

.home .testimonials .testimonial .loading {
  margin: 10px 0 0 0;
  position: absolute;
  width: 52px;
  height: 52px;
  right: 40px;
  bottom: 0px;
  border: solid #333 4px;
  border-radius: 100%;
}

.home .testimonials .testimonial .byLine {
  transform: translate(-61px, -36px);
  color: #333;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.02em;
}

.home .testimonials .quoteSign {
  top: 20px;
  left: 20px;
  color: #333;
  opacity: 1;
  line-height: 0 !important;
  display: inline;
  transform: none;
  animation-delay: 0s;
}

.home .testimonials .quoteSign.animate {
  animation: rotate 0.9s forwards;
}

.home .testimonials .testimonial blockquote {
  background-color: rgba(0,0,0,0);
  color: #333;
  border: solid 0px #333;
  box-shadow: 0px 0px 20px rgba(0,0,0,0);
  font-style: italic;
  border-radius: 12px;
  font-family: "work sans";
  text-align: center;
  font-size: 18px;
  letter-spacing: -0.04em;
  margin-bottom: 20px;
  line-height: 1.8;
  font-weight: 400;
  padding: 15px 40px 17px 40px;
}

.home .testimonials .testimonial {
  margin: auto !important;
  width: 100% !important;
}

/*************/
/* QUESTIONS */
/*************/

.questions {
  position: absolute;
  z-index: 30000;
  top: 80px;
  margin: auto;
  width: 100vw !important;
  /*background: linear-gradient(180deg, #ee6b60 0% , #ee5b4f 100%);*/
  background: linear-gradient(-360deg, #f5f5f5 0% , #f5f5f5 100%);
  height: calc(100vh - 80px);
  clip-path: url(#wave);
}

.wave {
  position: absolute;
}

.questions .navigation {
  position: absolute;
  width: 100% !important;
  text-align: center !important;
  left: auto !important;
  top: auto !important;
  bottom: 10px !important;
  justify-content: center !important;
  display: flex;
  transform: none !important;
  right: auto !important;
  z-index: 100;
}

.questions .question {
  position: absolute !important;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px !important;
  margin: 0px;
  height: calc(100vh - 100px) !important;
  max-width: auto !important;
  opacity: 0;
}

.questions .question:nth-child(2) {
  animation: fade-in-slide 0.9s forwards;
}

.questions .navigation .dot {
  color: rgba(0,0,0,0.2);
  font-size: 60px;
  cursor: pointer;
}

.questions .navigation .dot.active {
  color: #333;
}

.questions .question.myFadeIn {
  animation: fade-in-slide 0.9s forwards;
}

.questions .question.myFadeOut {
  animation: fade-out-slide 0.3s forwards;
}

.questions .question .content {
  margin: auto !important;
  max-width: 1000px !important;

  padding: 0 var(--wrapper-padding) 0 var(--wrapper-padding);
}

.questions .question p {
  border-left: none;
  color: #333 !important;
  font-style: normal;
  text-align: center;
  font-size: 78px !important;
  font-family: "work sans", helvetica, sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2 !important;
  padding: 0px 0px 0px 0px !important;
}

.questions .question p strong {
  color: #ee6b60;
  font-weight: 700;
}

.questions .question {
  margin: auto !important;
  width: 100% !important;
}

.rocket {
  position: absolute;
  z-index: 300000;
  left: auto;
  text-align: center;
  width: 100vw;
  color: #333;
  top: 200px !important;
  overflow: hidden;
}

.rocket .icon {
  color: #333;
  font-family: 'Times New Roman', Times, serif;
  font-size: 200px;
  opacity: 1;
  line-height: auto !important;
  animation: heartbeat 1s infinite forwards linear !important;
}

.rocket .animate {
  /*animation: rotate 0.9s forwards;*/
}

/*****************/
/* AUTHOR BUBBLE */
/*****************/

.authorBubble {
  display: flex;
  align-items: center;
  margin: 42px 0 0px 0;
}

.authorBubble .loading {
  position: relative;
  overflow: hidden;
  width: 80px;
  height: 80px;
  background-color: #f5f5f5;
  border-radius: 100% !important;
  display: flexbox;
  object-fit: cover;
  border: solid 0px #ee5b4f;
}

.authorBubble .img {
  border-radius: 100%;
  overflow: hidden;
}

.authorName {
  display: flexbox;
  font-size: 16px;
  padding: 0 0 0 12px;
  line-height: 1.2;
  font-weight: 700;
}

.authorName p {
  padding: 0;
  margin: 0;
}

.authorName span {
  display: flexbox;
  font-size: 12px;
  color: #aaa;
  font-weight: 600;
}

.authorName a {
  display: flexbox;
  border-bottom: 0px !important;
  font-size: 12px;
  color: #333 !important;
  font-weight: 600;
  font-weight: 600;
}

/****************/
/* WORK SECTION */
/****************/

.work {
  padding: calc(var(--block-padding)*3) 0 calc(var(--block-padding)*1) 0;
}

/***************/
/* POST OBJECT */
/***************/

.posts {
  max-width: var(--content-width);
  margin: 0px 0 calc(var(--block-padding)*2) 0;
}

.post {
  font-size: 21px;
  line-height: 36px;
  color: #333;
}

.post h1 {
  color: #333;
  /*color: #544c57;*/
  font-size: 60px;
  line-height: 72px;
  font-family: "Work Sans", "Helvetica neue", "Helvetica", sans-serif;
  font-family: Times, 'Times New Roman', serif;
  font-weight: 700;
  margin: 0 0 0 0;
  transition: ease-in-out 0.3s;
}
.post h1 u {
  text-decoration: none;
  /*box-shadow: inset 0px 0px 0 white, inset 0 -18px 0 #eee;*/
}

.post h2 {
  color: #333;
  font-size: 36px;
  line-height: 39px;
  font-weight: 600;
  font-family: "Work Sans", "Helvetica neue", "Helvetica", sans-serif;
  font-weight: 700;
  margin: calc(var(--block-padding) *1.2) 0 0px 0;
}

.post h3 {
  color: #544c57;
  font-size: 26px;
  font-weight: 600;
  font-family: "Work Sans", "Helvetica neue", "Helvetica", sans-serif;
  font-weight: 700;
  margin: 36px 0 30px 0;
}

.post .excerpt {
  color: #999;
  padding-top: 0px;
  font-size: 21px;
  font-weight: 500;
  line-height: 28px;
  margin: 0px 0 0px 0px;
}

.posts img.banner {
  max-width: 100%;
}

.btnBack {
  position: absolute;
  transform: translate(0px, -40px);
  display: flex;
  align-items: center;
  text-align: left;
  padding: 0px;
  line-height: 1;
  font-size: 18px;
  color: #777;
  border-radius: 100%;
}

/****************/
/* GRID OBJECTS */
/****************/

.grid {
  display: flex;
  align-items: top;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: space-between;
  max-width: var(--content-width);
}

.grid .post .content, .post .grid .end  {
  display: none;
}

.grid .post {
  display: flex;
  width: 520px;
  align-items: top;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  margin-bottom: 30px;
}

.grid .post .image .box {
  text-align: right;
}

.grid .post .image .box .loading {
  position: relative;
  width: 270px;
  height: 270px;
  margin: 0 24px 20px 0 ;
  background-color: #f5f5f5;
  transition: 0.3s;
}

.grid .post .image img {
  width: 100%;
  height: 270px !important;
  object-fit: cover;
  margin: 0 auto 0 0;
}

.grid .post h1 {
  font-size: 32px;
  line-height: 38px;
  margin: 0 0 0 0px;
}

.grid .post a.title h1:hover {
  color: #666;
}

.grid .post .excerpt {
  color: #999;
  padding-top: 0px;
  font-style: italic;
  font-size: 18px;
  line-height: 22px;
  margin: 0px 0 0px 0px;
}

.grid .post .text {
  display: flexbox;
  position: relative;
  width: 100%;
  margin: 0 20px 0 0px;
}

.grid .post .readMore  {
  display: none;
  position: absolute;
  transform: translate(0px, -30px);
  font-size: 14px;
  padding: 16px;
  line-height: 16px;
  border-radius: 6px;
  font-weight: bold;
  background-color: #eee;
  color: #333;
  transition: ease-in-out 0.3s;
  cursor: pointer;
}

.grid .post a.readMore:hover {
  background-color: #ee5b4f;
  color: #fff;
}

/****************/
/* LIST OBJECTS */
/****************/

.list  {
  display: flex;
  flex-direction: column;
  max-width: var(--content-width);
}

.list .post  {
  display: flex;
  max-width: var(--content-width);
}

.list .post a.title h1 {
  color: #333;
}

.list .post a.title h1:hover {
  color: #666;
}

.list .post .text {
  display: flexbox;
  position: relative;
  width: 70%;
}

.list .post .text hr {
  padding: 20px 0 0px 0;
  border: none;
  text-align: left;
  color: #ccc;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.list .post .text hr::after {
  content: "***";
}

.list .post .text blockquote {
  border-left: 6px solid #eee;
  margin: 0px;
  padding: 3px 0 6px 24px;
  font-style: italic;
}


.list .post .text .wp-block-table {
  padding: 20px 0px 20px 0;
  margin: 0px;
}

.list .post .text .wp-block-table td {
  padding: 14px;
  background-color: #eee;
  font-size: 18px;
}

.list .post .text p.end {
  transform: translate(0px, -120px);
  height: 100px;
  background: linear-gradient(to top, rgba(255,255,255,0.8) 0%,rgba(255,255,255,0) 80%); 
}

.list .post .text a.readMore {
  position: absolute;
  transform: translate(0px, -100px);
  font-size: 14px;
  padding: 16px;
  line-height: 16px;
  border-radius: 6px;
  font-weight: bold;
  background-color: #eee;
  color: #333;
  transition: ease-in-out 0.3s;
  cursor: pointer;
  transition: 0.3s;
}

.list .post .text a.readMore:hover {
  background-color: #ee5b4f;
  color: #fff;
}

.list .post .image .box .loading {
  position: relative;
  width: 100%;
  height: 600px;
  background-color: #f5f5f5;
  transition: 0.3s;
}

.list .post .image {
  display: flexbox;
  border: none;
  color: #666;
  padding: 32px 0 0 60px;
  margin: 0px;
  text-align: right;
  width: 50%;
}

.list .post .image .box {
  width: 100%;
  margin: 0px 0 0px 0;
  line-height: 1;
}

.post .box img {
  width: 100%;
  transition: 0.3s ease-in-out;
}

.post .box a .loading:hover {
  transform: scale(1.02);
}

.list .post .image .details a {
  opacity: 0.3;
  font-size: 12px;
  color: #333;
  transition: 0.3s ease-in-out;
  font-family: "Work Sans", "Helvetica neue", "Helvetica", sans-serif;
  font-weight: 600;
}

.list .post .image .details a:hover {
  color: #666;
  opacity: 1;
}

.list .post .image .details .link {
  transform: translate(-3px, 6px);
  width: 20px;
  color: #666;
}

/**************/
/* BLOG PAGE  */
/**************/

.blogPage {
  padding: calc(var(--block-padding)*3) 0 calc(var(--block-padding)*1) 0;
}

.notice {
  color: #999;
  font-weight: 400;
  font-style: italic;
  font-size: 22px;
}

/**************/
/* BLOG POST  */
/**************/

.blogPost {
  padding: calc(var(--block-padding)*3) 0 calc(var(--block-padding)*1) 0;
}

/*************/
/* CLIENTS  */
/*************/


.clients {
  display: flex;
  max-width: var(--wrapper-width) !important;
  margin: 0;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: calc(var(--block-padding) *0.5);
}

.client {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: top;
  flex-direction: column;
  text-align: center;
}

.client .name {
  font-size: 12px;
  line-height: 1.3;
  padding: 8px 0px 0px 0;
  width: 120px;
  color: #333;
}

.client .logo {
  position: relative;
  width: 100px;
  object-fit: cover;
  border-radius: 100%;
  height: 100px;
  background-color: #f5f5f5;
  transition: 0.3s;
}

.client .banner {
  border-radius: 100%;
  overflow: hidden;
}

.client .banner .loading {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  overflow: hidden;
  background-color: #f5f5f5;
  transition: 0.6s;
}

.client .banner .loading div {
  border-radius: 100%;
  overflow: hidden;
}


.client .banner .loading:hover {
  transform: scale(1.05);
}

/*************/
/* COACHING  */
/*************/

.coaching {
  max-width: 800px;
  padding: 0 var(--wrapper-padding) 0 var(--wrapper-padding) !important;
  opacity: 0;
  margin: calc(100vh + 0px) auto 0px auto;
  animation: fade-in forwards 0.8s ease-in-out;
  animation-delay: 1s;
}

.coaching .content h2 {
  max-width: 100%;
  text-align: left;
  font-weight: 700;
  font-size: 40px !important;
  line-height: 1.3;
}

.coaching .content p {
  font-size: 26px;
  line-height: 1.8;
}

.coaching ul {
  margin-top: 0px;
}

.coaching .content img {
  width: 110px;
  height: auto;
}

.coaching .tiles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 60px;
}

.coaching .content h3 {
  font-size: 34px;
  text-align: center;
  margin: 16px;
  padding: 20px 20px 20px 20px;
  color: #333;
  width: 300px !important;
  height: 300px !important;
  font-weight: 600;
  border-radius: 1%;
  box-shadow: 0px 0px 0px #333;
}

.coaching .content h3 img {
  display: block;
  margin: 30px auto 50px auto;
  animation: heartbeat 2s infinite forwards linear !important;
}

.coaching .content h3 strong {
  color: #333;
  font-weight: 900;
  /*box-shadow: inset 0px 0px 0 white, inset 0 -1.1em 0 #d5ee11;*/
}

.coaching ul li {
  font-size: 20px;
  line-height: 1.6;
}

.coaching .content ul  {
  padding-left: 1.2em !important;
}

.coaching .content ul li  {
  line-height: 1.8;
  padding: 0 0 30px 0;
  list-style: circle;
}

.coaching .content p a, .coaching .content ul a  {
  color: #333;
  font-weight: 600;
  border-bottom: solid 2px #333;
  transition: 0.3s;
}

.coaching .content p a:hover, .coaching .content ul a:hover {
  color: #666 !important;
  border-bottom: solid 2px #666 !important;
}

.coaching .contentBox {
  display: flex;
  justify-content: space-around;
  color: #333 !important;
  margin: 60px 0 30px 0 !important;
  padding: 30px 20px 30px 30px;
  border-radius: 12px;
  background: linear-gradient(-180deg, #f5f5f5 0% , #eee 100%);
  border: solid #ee837a 0px;
}

.coaching .contentBox div {
  display: flex;
}

.coaching .contentBox .col p {
  color: #333 !important;
}

.coaching .contentBoxDark {
  display: flex;
  justify-content: space-around;
  color: #333;
  margin: 20px 0 0px 0 !important;
  padding: 0px 0px 0px 0px;
  border-radius: 12px;
}

.coaching .contentBoxDark p {
  font-size: 21px !important;

}

.coaching .contentBoxDark h2 {
  line-height: 1.3;
}

.coaching .contentBoxDark p a:hover {
  
}

.coaching .btnContact {
  width: 100% !important;
  text-align: center;
  display: flex;
  font-style: italic;
  font-size: 22px;
  justify-content: center;
  align-items: center;
}

.coaching .btnContact a {
  font-size: 26px;
  color: #fff;
  font-style: normal;
  border-radius: 10px;
  font-weight: 600;
  padding: 26px 30px 26px 30px;
  margin: 30px 0 30px 0;
  transition: 0.3s;
  border-radius: 6px;
  
  background: linear-gradient(180deg, #ee837a 0% , #ee6b60 100%);
  border: solid 0px #666;
  box-shadow: 6px 6px 0px #333;
}

.coaching .btnContact a:hover {
  color: #fff;
  border: solid 0px #333;
  box-shadow: 10px 10px 0px #333;
}

.coaching .col {
  display: block !important;
  width: 80%;
}

.coaching .col p {
  font-size: 22px;
  color: #333;
  text-align: left;
  line-height: 1.6;
  width: 95%;
}


.coaching .authorName {
  display: none !important;
}

.coaching .authorBubble {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin: 30px 30px 0px 30px !important;
}

.coaching .authorBubble .loading {
  width: 100px;
  height: 100px;
  border: solid 6px #fff;
}

.coaching .authorName span {
  font-size: 12px;
  color: #aaa;
  font-weight: 600;
}

.coaching .authorName a {
}

/*************/
/* PROJECTS  */
/*************/

.projects {
  max-width: var(--wrapper-width);
  margin: 0;
}

.projects .project {
  position: relative;
}

.project .banner img {
  width: 100%;
  transition: 0.3s;
  background-color: #fff !important;
}

.project .banner .loading {
  position: relative;
  width: 100%;
  height: 300px;
  margin-bottom: 10px;
  background-color: #f5f5f5;
  transition: 0.3s;
}

.project .banner .loadingBanner {
  position: relative;
  width: 100%;
  height: 565px;
  background-color: #f5f5f5;
  transition: 0.3s;
}

.project .banner a .loading:hover {
  transform: scale(1.02);
}

.project .content {
  display: flex;
  padding: 0px;
  z-index: 300;
  flex-direction: row;
  justify-content: space-between;
  align-items: top;
}

.project h1 {
  color: #333;
  font-size: 56px;
  line-height: 58px;
  font-family: Times, 'Times New Roman', serif;
  font-weight: 700;
  margin: var(--block-padding) 0 0 0;
  transition: ease-in-out 0.3s;
}

.project a h1:hover {
  color: #666;
}

.project h3 {
  color: #544c57;
  font-size: 26px;
  font-weight: 600;
  font-family: "Work Sans", "Helvetica neue", "Helvetica", sans-serif;
  font-weight: 700;
  margin: 36px 0 30px 0;
}

.project .excerpt {
  display: none;
}

.project .text {
  font-size: 21px;
  line-height: 36px;
  color: #333;
  max-width: 55%;
}

.project .text a {
  color: #666;
  border-bottom: solid 2px #666;
  transition: 0.3s;
}

.project .text a:hover {
  border-bottom: solid 2px #666;
}

.project .attachedImages {
  display: flexbox;
  text-align: right;
}

.project .attachedImages .attachedImage {
  position: relative;
  display: flexbox;
  transition: 0.3s;
  margin-top: calc(var(--block-padding) *1.5);
}

.project .attachedImages img {
  max-width: 100%;
  transition: 0.3s;
}

.project .attachedImages .attachedImage .loading {
  position: relative;
  width: 450px;
  height: 320px;
  background-color: #f5f5f5;
  transition: 0.3s;
}

.project .attachedImages .attachedImage a .loading:hover {
  transform: scale(1.02);
}

.project .attachedImages .attachedImage span {
  display: block;
  position: relative;
  z-index: 100;
  opacity: 1;
}

.project .attachedImages span img {
  margin-top: 12px;
  background-color: #333;
  border-radius: 100%;
  padding: 4px;
  width: 20px;
}

.project .content.single .text {
  padding-top: 30px;
}

.projectsPage .projects {
  display: flex;
  max-width: var(--wrapper-width);
  align-items: top;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: space-between;
}

.projectsPage .project {
  width: 520px !important;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 0px;
}

.projectsPage .projects .project .banner {
  max-width: 100%;
}

.projectsPage .text {
  max-width: 100%;
}

.projectsPage .project .text h1 {
  display: none;
}

.projectsPage .project .content  {
  margin-bottom: 0 !important;
  position: relative;
}

.projectsPage .content .text .excerpt, .projectsPage .content .text .excerpt p {
  display: block;
  font-size: 18px;
  color: #333;
  padding-top: 0px;
  margin-top: 2px;
  letter-spacing: 0px;
  font-weight: 400;
  line-height: 1.2;
  z-index: 200;
}

.moreProjects .anchor {
  font-size: 42px;
  line-height: 48px !important;
}

.moreProjects .project {
  width: 340px !important;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 0px;
  transition: 0.3s;
}

.moreProjects .project .banner .loading {
  height: 195px;
}

.moreProjects .project .banner .loading:hover {
  transform: scale(1.02);
}

.projectsPage .blockFull .blockLeft .anchor {

  margin-top: 1px;
  color: #333;
}

.projectsPage .blockFull .blockText {
  padding-top: 0px;
}

.projectsPage .blockFull .blockText p {
  width: 100%;
  padding-top: 5px;
}


/******************/
/* CONTACT BANNER */
/******************/

.contactBanner {
  opacity: 0;
  animation: fade-in forwards 0.8s ease-in-out;
  animation-delay: 1s;
  background: #f1f1f1;
  /*margin-top: calc(var(--block-padding)*2) !important;*/
  padding: calc(var(--block-padding)*0.4) var(--wrapper-padding) calc(var(--block-padding)*1.1) var(--wrapper-padding);
}

.contactBanner .content {
  max-width: var(--content-width);
  display: flex;
  margin: auto;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  border-bottom: solid 0px #fff;
}

.contactBanner .text {
  padding: 18px 0 0 0;
  width: 50%;
  color: #777;
  margin-right: 0px;
  font-weight: 400;
  letter-spacing: -0.04em;
  font-size: 18px;
  line-height: 30px;
  margin: 20px auto 0px 0;
}

.contactBanner a  {
  color: #777 !important;
  text-decoration: none;
  border-bottom: solid 0px #777;
  transition: 0.3s;
}

.contactBanner u:hover, .contactBanner a:hover {
  color: #666 !important;
  border-bottom: solid 2px #666;
}

.contactBanner a {
  color: #fff;
}

.contactBanner .authorBubble  {
  margin: 0px;
}

.contactBanner .authorBubble .authorPic  {
  width: 100px;
  height: 100px;
  border: solid 8px rgba(255,255,255,0.1);
}

.contactBanner .authorBubble .authorName  {
  font-size: 16px;
  color: #fff;
}

.contactBanner .authorBubble .authorName span {
  color: #fff;
}

.contactBanner .authorBubble a {
  border-bottom: none;
  color: #aaa;
  transition: 0.3s;
}

.contactBanner .authorBubble a:hover {
  color: #fff;
}

.contactButton div {
  display: inline-block;
}

.contactButton .authorBubble .authorName {
  display: none;
}

.contactButton .authorBubble .loading {
    width: 32px !important;
    height: 32px !important;
    transform: translate(0px, 20px);
    border: solid 3px #333;
    transition: 0.3s;
}

.contactButton .authorBubble .loading:hover {
    transform: translate(0px, 20px) scale(1.1);
}

.contactButton a img {
  width: 32px;
  margin-left: 6px;
  border: solid 3px #333;
  transition: 0.3s;
  border-radius: 100%;
  background-color: #333;
  transform: translate(0px, 20px);
}

.contactButton a {
  border: none !important;
}

.contactButton a img:hover {
  transform: translate(0px, 20px) scale(1.1);
}

/*******************/
/* CONSULTING PAGE */
/*******************/

.consulting {  
  padding: calc(var(--block-padding)*3) 0 calc(var(--block-padding)*3) 0;
  font-size: 21px;
  line-height: 36px;
}

.consulting a {
  text-decoration: none !important;
  border-bottom: 2px solid #333;
}

.consulting .anchor {
  padding-right: 30px;
}

/*.consulting .blockFull {
  flex-direction: column;
  width: var(--wrapper-width);
}

.consulting .blockLeft {
  max-width: var(--wrapper-width);
}
*/
.consulting .blockText, .consulting .blockText p {
  border: 0px;
  padding-left: 0px;
}

/********************/
/* SERVICES PAGE */
/********************/

.services {
  padding: calc(var(--block-padding)*2) 0 calc(var(--block-padding)*3) 0;
  font-size: 21px;
  line-height: 36px;
}

.services h2 {
  font-size: 32px;
  line-height: 39px;
  margin: 0;
}

.services a {
  text-decoration: none !important;
  border-bottom: 2px solid #333;
}

.services .anchor {
  text-indent: -0.5em;
}

/*******************/
/* CONTACT PAGE */
/*******************/

.contact {
  padding: calc(var(--block-padding)*3) 0 calc(var(--block-padding)*2.6) 0;
  font-size: 21px;
  line-height: 36px;
}

.contact p a {
  color: #333;
  text-decoration: none !important;
  border-bottom: 2px solid #333;
}

.contact p a:hover {
  color: #666;
  text-decoration: none !important;
  border-bottom: 2px solid #666;
}

.contact .blockText {
  margin-top: 99px;
}

/*******************/
/* PRIVACY PAGE */
/*******************/

.privacy {
  padding: calc(var(--block-padding)*1) 0 calc(var(--block-padding)*2) 0;
  font-size: 21px;
  line-height: 36px;
}

.privacy h2 {
  font-size: 32px;
  line-height: 39px;
  margin: 0;
}

.privacy .content {
  max-width: 60%;
}

.privacy p u, .privacy p a {
  color: #333;
  text-decoration: none !important;
  border-bottom: 2px solid #333;
  transition: 0.3s;
}

.privacy p u:hover, .privacy p a:hover {
  color: #666;
  text-decoration: none !important;
  border-bottom: 2px solid #666;
}

/**********/
/* FOOTER */
/**********/

.footer {
  opacity: 0;
  animation: fade-in forwards 0.8s ease-in-out;
  animation-delay: 1s;
  background: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: var(--wrapper-padding);
  font-size: 12px;
  color: #777;
  background-color: #f1f1f1;
}


.footer .links a {
  font-weight: 700;
  color: #777;
  transition: 0.3s;
}

.footer .links a:hover {
  font-weight: 700;
  color: #aaa;
}

.footer .credits {
  font-weight: 400;
}



/* COOKIE BANNER */

.cookieContainer {
  position: absolute;
  z-index: 300000;
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: rgba(0,0,0,0.6);
}

.cookieBanner {
  margin: auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
  width: 300px !important;
  height: auto;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0px 0px 100px rgba(0,0,0,0.5);
}

.cookieBanner .title {
  font-weight: 700;
  font-size: 12px;
}

.cookieBanner .desc {
  font-size: 14px;
}

.cookieBanner .links {
  font-size: 12px;
  text-align: center;
}

.cookieBanner .links a {
  border-bottom: solid #000 1px;
}

.cookieBanner .btnAccept {
  font-size: 16px;
  background-color: #000;
  display: block;
  text-align: center;
  padding: 12px;
  color: #fff;
  border-radius: 20px;
  cursor: pointer;
  margin: 0 0 10px 0;
}

.cookieBanner .btnReject{
  font-size: 16px;
  background-color: #eee;
  display: block;
  text-align: center;
  padding: 12px;
  color: #777;
  border-radius: 20px;
  cursor: pointer;
  margin: 0 0 10px 0;
}

/*******************/
/* LOADING SPINNER */
/*******************/

.spinner {
  margin: 100px 0 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  animation-delay: -0.16s;
}

.loader {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: calc(50% - 2em);
}

.loader .spinner {
  margin: 0px;
}

.loader .spinner > div {
  background-color: rgba(255,255,255,0.6);
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    transform: scale(0);
  } 40% { 
    transform: scale(1.0);
  }
}

/**************/
/* ANIMATIONS */
/**************/

@keyframes fade-in{
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}

@keyframes fade-out{
  0% {
    opacity: 100;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade-in-blur {
  0% {
    opacity: 0;
    filter: blur(2px);
  }
  100% {
    filter: blur(0px);
    opacity: 100;
  }
}

@keyframes fade-in-slide {
  0% {
    opacity: 0;
    transform: translate(0px, 80px);
  }
  90% {
    opacity: 100;
    transform: translate(0px, 1px);
  }
  100% {
    opacity: 100;
    transform: translate(0px, 0px);
  }
}


@keyframes fade-out-slide {
  0% {
    opacity: 100;
    transform: translate(0px, 0px);
  }
  90% {
    opacity: 0;
    transform: translate(0px, 29px);
  }
  100% {
    opacity: 0;
    transform: translate(0px, 30px);
  }
}

@keyframes fade-in-slide-down {
  from {
    opacity: 0;
    transform: translate(0px, -50px);
  }
  to {
    opacity: 100;
    transform: translate(0px, 0px);
  }
}

@keyframes fade-in-slide-right {
  0% {
    opacity: 0;
    transform: translate(-20px, 0px);
  }
  80% {
    
  }

  100% {
    opacity: 100;
    transform: translate(0px, 0px);
  }
}

@keyframes heartbeat {
  0%
  {
    opacity: 1;
    transform: scale(1.05);
  }
  50%
  {
    opacity: 1;
    transform: scale(1);
  }
  100%
  {
    opacity: 1;
    transform: scale(1.05);
  }
}

@keyframes rotate {
  0%
  {
    opacity: 1;
    transform: rotate(0deg);
  }

  40%
  {
    opacity: 0;
  }

  100%
  {
    opacity: 1;
    transform: rotate(360deg);
  }
}

@keyframes rotateSmooth {
  0%
  {
    opacity: 1;
    transform: rotate(0deg);
  }

  40%
  {
    opacity: 1;
  }

  100%
  {
    opacity: 1;
    transform: rotate(360deg);
  }
}

@keyframes move {
  0%
  {
    opacity: 1;
    transform: translate(0px, -10px);
  }

  50%
  {
    opacity: 1;
    transform: translate(0px, 0px);
  }

  100%
  {
    opacity: 1;
    transform: translate(0px, -10px);
  }
}


@keyframes sway {
  0%
  {
    opacity: 1;
    transform: scale(1);
  }

  50%
  {
    opacity: 1;
    transform: scale(1.05);
  }

  100%
  {
    opacity: 1;
    transform: scale(1);
  }
}