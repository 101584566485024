/**************/
/* RESPONSIVE */
/**************/

@media only screen and (max-height: 900px) {

  .questions .question div  {
    font-size: 80px !important;
  }
}

@media only screen and (max-width: 1168px) {

    :root {
      --block-padding: 28px;
    }

    nav.menuTop ul li a, nav.menuTop ul li div.langSel {
      margin: 0 0px 0 0px;
    }
  
    .grid .post {
      width: 48%;
      margin-bottom: var(--block-padding);
    }
  
    .grid .post .image .loading {
      max-width: 600px;
      width: 220px !important;
      height: 250px !important;
    }

    .list .post .image .loading {
      height: 540px !important;
    }
  
    .projectsPage .projects .project {
      max-width: 48%;
    }
  
    .moreProjects .project {
      width: 48% !important;
    }

    .project .banner .loadingBanner {
      height: 500px;
    }
  
    .project .attachedImages .attachedImage .loading {
      width: 380px;
      height: 250px;
    }

    .moreProjects .project .banner .loading {
      height: 230px;
    }

    .blockLeft .image .loading {
      width: 400px;
      height: 540px;
    }

    .blockText .image .loading {
      width: 100%;
      height: 300px;
      margin-top: 4px;
    }

    .anchor {
      font-size: 56px;
      line-height: 65px !important;
    }
  
  }

  /************/
  /* IPAD PRO */
  /************/

  @media only screen and (max-width: 1024px) {
  
    .home .testimonials .myContent {
      width: 550px !important;
    }
    
    .home .testimonials .testimonial .content {
      width: 500px !important;
    }

    .home .testimonials .testimonial blockquote {
      padding: 18px 26px 18px 26px;
    }

  }

  /***************/
  /* MOBILE MENU */
  /***************/
  
  @media only screen and (max-width: 1015px) { 
  
    nav.menuTop {
      display: block;
      position: fixed;
      width: 100vw !important;
      height: var(--vh) !important;
      top: -2000px;
      margin: 0px !important;
      left: 0px !important;
      right: auto !important;
      z-index: 600000;
    }

    .home nav.menuTop ul li a {
      text-shadow: none;
    }
  
    .btnHamburger {
      display: flex;
      position: absolute;
      align-items: flex-end;
      justify-content: center;
      font-size: 32px;
      right: calc(var(--wrapper-padding)*0.9);
      top: 32px;
      width: 30px;
      height: 30px;
      text-align: center;
      transition: 0s;
      line-height: 1.2;
      z-index: 700000;
      opacity: 0;
      animation: fade-in forwards 0.5s ease-in-out;
      border-radius: 100%;
      padding: 6px;
      border: solid 1px;
      border-color: transparent;
      color: #333;
    }
  
    nav.menuTop ul {
      width: 100vw !important;
      transform: none !important;
      padding-top: 0px !important;
      top: 0px !important;
      right: 0px !important;
      width: 100vw !important;
    }
  
    nav.menuTop ul.menuList {
      background: rgba(255,255,255,1);
      padding: calc(var(--block-padding) * 2.6) 0px 0px 0 !important;
      opacity: 0;
      height: var(--vh);
      transition: 0.5s;
      font-weight: 400;
      list-style: disc;
    }
  
    nav.menuTop ul li {
      display: block;
      width: 100vw !important;
      margin-left: 0px !important;
      text-align: center;
      padding: 10px 0 10px 0;
    }
  
    nav.menuTop ul li a {
      display: block;
      list-style: disc;
      font-size: 22px;
      font-weight: 400;
      width: auto !important;
      text-align: center;
      color: #333 !important;
    }

    .home nav.menuTop ul li a:hover, .home nav.menuTop ul li div.langSel:hover {
      background: #fff;
      color: #333 !important;
    }

    nav.menuTop ul li a:hover, nav.menuTop ul li div.langSel:hover {
      background: #fff;
      border-radius: 0px !important;
      color: #333 !important;
      border-color: transparent;
      border-radius: 10px;
    }

    nav.menuTop ul li div.langSel {
      display: block;
      list-style: disc;
      font-size: 22px;
      padding: 12px;
      margin: auto;
      text-shadow: none !important;
      max-width: 100px !important;
      text-align: center;
      font-weight: 400;
      border: solid 1px !important;
      color: #333 !important;
    }
  
    nav.menuTop ul li a.active {
      display: inline-block;
      font-size: 22px !important;
      background: #fff;
      font-weight: 400;
      border-radius: 10px;
      padding: 10px;
      color: #e83737 !important;
      border-bottom: 0px;
    }
  }
  
  @media only screen and (max-width: 968px) {
    .grid .post .image .loading {
      width: 200px !important;
      height: 250px !important;
    }
    
    .grid .post .text h1 {
      font-size: 26px !important;
      line-height: 32px;
    }

    .grid .post .text .excerpt {
      font-size: 16px !important;
      line-height: 21px;
    }

    .home .testimonials .myContent {
      width: 450px !important;
    }
    
    .home .testimonials .testimonial .content {
      width: 450px !important;
    }
  }

  @media only screen and (max-width: 720px) {
    .grid .post .image .loading {
      width: 130px !important;
      height: 250px !important;
    }
    
    .grid .post .text h1 {
      font-size: 20px !important;
      line-height: 32px;
    }
  }

  @media only screen and (max-width: 768px) {
    
    :root {
      --block-padding: 28px;
    }
  
    .anchor {
      line-height: 65px !important;
      padding-right: 0px !important;

    }

    nav.menuTop ul {
      right: calc(var(--wrapper-padding)*1);
    }
  
    .CookieConsent {
      align-items: center !important;
      justify-content: space-between !important;
      padding: 26px 0 26px 0 !important;
      box-shadow: 0px 0px 40px rgba(0,0,0,0.3) !important;
    }
  
    .CookieConsent div {
      padding: 0 50px 0 50px !important;
      margin: 0 !important;
    }
  
    .CookieConsent div button {
      margin: 20px 0 18px auto !important;
    }
  
    .headerQuote {
      width: 530px;
      font-size: 24px;
    }
  
    .blockFull {
      display: block !important;
    }
  
    .blockLeft {
      width: 100%;
    }
  
    .blockText {
      border-left: none;
      padding-left: 0px;
      max-width: 100%;
    }
  
    .blockText h2 {
      margin-top: 32px;
    }

    .clients p {
      font-size: 24px;
      line-height: 1.7;
      text-transform: uppercase;
    }
  
    .grid .post {
      width: 48%;
      margin-bottom: var(--block-padding);
    }
  
    .grid .post .image img {
      max-width: 200px;
      height: 200px !important;
    }
  
    .grid .post .image .box .loading {
      width: 130px !important;
      height: 190px !important;
    }
  
    .grid .post .text h1 {
      font-size: 20px !important;
      line-height: 23px;
    }

    .grid .post .text .excerpt {
      font-size: 14px !important;
      line-height: 18px;
    }
  
    .list .post .image .box .loading {
      height: 400px !important;
    }
  
    .privacy .content, .contact .content, .imprint .content {
      max-width: 80%;
    }
  
    .projectsPage .project .content .text .excerpt, .projectsPage .project .content .text .excerpt p {
      font-size: 14px !important;

      font-weight: 400 !important;
    }
  
    .project .banner .loading {
      width: 100%;
      height: 180px;
    }

    .moreProjects .project .banner .loading {
      height: 165px;
    }
  
    .project .banner .loadingBanner {
      width: 100%;
      height: 350px;
    }

    .project .excerpt {
      line-height: 18px !important;
    }
  
    .project .attachedImages .attachedImage .loading {
      width: 260px;
      height: 200px;
    }

    .home .testimonials .myContent {
      width: 350px !important;
    }
    
    .home .testimonials .testimonial .content {
      width: 350px !important;
    }

    .home .testimonials .testimonial blockquote {
      font-size: 18px !important;
      line-height: 1.6;
      padding: 8px 18px 8px 18px !important;
      border-radius: 16px;
    }

    .home .testimonials .testimonial .loading {
      right: 30px;
    }

    .home .testimonials .testimonial .byLine {
      transform: translate(-50px, -36px);
      color: #e83737;
      font-size: 12px;
      display: none;
    }

    .home .testimonials .quoteSign {
      top: 20px;
      left: 10px;
    }

    .consulting .testimonials {
      display: none;
    }

    .blockLeft .image .loading {
      width: 100% !important;
      height: 320px;
      margin-bottom: 0px;
      margin-right: 0px;
    }

    .blockText .image .loading {
      width: 80% !important;
      height: 320px;
      margin-bottom: 0px;
      margin-right: 0px;
    }

    .contact .blockText {
      margin-top: 0px;
    }

    .coaching .authorBubble {
      margin: 30px 30px 0px 10px !important;
    }

    .coaching .btnContact a {
      font-size: 18px;
      padding: 26px 30px 26px 30px;
    }

    .coaching .contentBox {
      margin: 80px 0 60px 0 !important;
    }

    .coaching .contentBoxDark {
      margin: 60px 0 0px 0 !important;
    }
  
  }
  
  
  @media only screen and (max-width: 600px) { 
    
    :root {
      --wrapper-padding: 28px !important;
      --block-padding: 28px !important;
    }
  
    /**********/
    /* OTHERS */
    /**********/
  
    .anchor {
      font-size: 51px;
      line-height: 62px !important;
    }

    .home #btnHamburger {
      top: 28px;
    }

    #btnHamburger {
      top: 28px;
    }

    #logo {
      animation: fade-in forwards 0.5s ease-in-out;
    }

    nav.menuTop ul li {
      margin-left: 5px;
    }

    .home .wrapper, .home .tint {
      height: auto !important;
      min-height: -webkit-fill-available !important;
      background-size: auto calc(var(--vh) / 1 );
      background-attachment: none;
      background-position: left top;
    }
  
    .home .about {
      padding:  calc(var(--block-padding)*2.6) 0 calc(var(--block-padding)*1.5) 0;
      /*border-bottom: solid 10px #f7f7f7;*/
    }
  
    .home .about .anchor {
      padding-bottom: 6px;
    }

    .about, .work, .contact, .blogPage, .blogPost  {
      padding-top: calc(var(--block-padding)*1);
    }
  
    .home .work {
      padding-top: calc(var(--block-padding)*1);
      padding-bottom: calc(var(--block-padding)*1.5);
    }

    .home .clientBlock {
      padding-top: calc(var(--block-padding)*1);
      padding-bottom: calc(var(--block-padding)*1.5);
    }
  
    .home .blogPage {
      padding-top: calc(var(--block-padding)*1.5);
    }
  
    .CookieConsent {
      display: block !important;
      align-items: center !important;
      text-align: center !important;
      box-shadow: 0px 0px 40px rgba(0,0,0,0.3) !important;
    }
  
    .CookieConsent div {
      margin: auto !important;
    }
  
    .CookieConsent div button {
      margin: 20px auto 0 auto !important;
    }
  
    #logo {
      width: auto;
      text-align: left;
    }
  
    #logo a {
      font-size: 36px;
      text-shadow: 0px 0px 0px rgba(0,0,0,0.3);
    }
  
    #logo a:hover {
      font-size: 36px !important;
    }

    .home #logo  {
      top: 60px !important;
      width: 100% !important;
      left: 0px !important;
    }

    .home #logo a, .home #logo  a span {
      font-size: 60px !important;
      text-shadow: 0px 0px 0px rgba(0,0,0,0.3);
    }

    .home #logo a span {
      font-size: 60px !important;
    }
  
    .headerQuote {
      max-width: 90%;
      font-size: 16px;
      bottom: 15%;
      border-left: none;
    }
  
    .headerQuote div.text {
      border-left: 0px !important;
      padding-left: 1em !important;
      text-align: center !important;
    }


    .clients p {
      font-size: 18px;
      line-height: 1.8;
      letter-spacing: -1px;
      text-transform: uppercase;
    }
  
    .projects .project .content {
      display: block;
      margin-bottom: calc(var(--block-padding)*0.8) !important;
    }
    
    .projects .project .content .text {
      max-width: 100%;
    }
  
    .project .banner .loadingBanner {
      width: 100%;
      height: 180px;
    }
  
    .projects .project .content .text h1 {
      font-size: 36px;
      line-height: 38px;
    }
  
    .projects .project .content .text h3 {
      font-size: 32px;
      line-height: var(--block-padding);
    }
  
    .projects .project .content .attachedImages {
      text-align: center;
    }
  
    .project .attachedImages .attachedImage .loading {
      width: 100%;
      height: 230px;
      margin: auto;
    }
  
    .project .attachedImages .attachedImage {
      margin-top: 40px;
    }
  
    .project .attachedImages .attachedImage img {
      max-width: 100%;
    }
  
    .project .content.single .text {
      padding-top: 0px;
    }
  
    .projectsPage .project .content {
      margin-bottom: var(--block-padding);
    }
  
    .projectsPage .project {
      max-width: 100% !important;
      padding-bottom: 1px;
    }
  
    .moreProjects .project {
      width: 100% !important;
    }
  
    .blockLeft {
      padding: 0px;
      width: 100%;
    }
  
    .blockText {
      padding-bottom: 0px;
      width: 100%;
    }

    .blockLeft .authorBubble {
      padding-top: 0px;
      padding-bottom: 20px;
    }

    .home .testimonials {
      bottom: 62px !important;
    }

    .home .testimonials .myContent {
      width: 300px !important;
    }
    
    .home .testimonials .testimonial .content {
      width: 300px !important;
    }

    .home .testimonials .testimonial blockquote {
      font-size: 16px !important;
      line-height: 1.6;
      padding: 12px 26px 32px 26px !important;
      border-radius: 16px;
    }

    .home .testimonials .testimonial .loading {
      right: 16px;
      bottom: -12px;
    }

    .home .testimonials .testimonial .byLine {
      transform: translate(-50px, -6px);
      color: #e83737;
      font-size: 0px;
    }

    .home .testimonials .quoteSign {
      top: 20px;
      font-size: 150px;
      left: -8px;
    }

    .questions {
      /*height: -webkit-fill-available !important;*/
      height: calc(100vh - 150px) !important;
    }

    .questions .question p  {
      font-size: 36px !important;
    }

    .rocket .icon {
      font-size: 120px;
    }

    .rocket {
      top: 170px !important;
    }

    .coaching {
      margin-top: calc(100vh - 120px) !important;
      margin-bottom: 1px;
    }

    .coaching .content img {
      width: 140px;
    }

    .coaching .content h3 {
      margin: 20px 0 18px 0;
      font-size: 22px !important;
      width: 250px !important;
      height: 250px !important;
      margin: 20px auto 0px auto;
      padding: 10px 20px 20px 20px;
    }

    .coaching .content h3:nth-child(-1) {
      margin: 30px auto 0px auto;
    }

    .coaching .content {
      margin: 0px 0 0px 0;
      font-size: 20px;
    }

    .coaching .btnContact {
      padding-bottom: 40px;
    }

    .coaching p {
      font-size: 18px !important;
    }

    .coaching .content h2 {
      font-size: 28px !important;
      line-height: 1.4;
    }

    .coaching .contentBox {
      display: flex;
      flex-direction: column !important;
      margin: 0px !important;
      padding: 0px 36px 30px 36px !important;
    }

    .coaching .contentBox .col p {
    }

    .coaching .contentBoxDark {
      margin: 0px 0px 0px 0px !important;
      padding: 20px 0px 0px 0px !important;
    }

    .coaching .contentBoxDark ul li {
      font-size: 18px !important;
    }

    .coaching .contentBoxDark h2 {
      font-size: 24px !important;
    }

    .coaching .col {
      width: 100%;
    }

    .coaching .col p {
      font-size: 18px;
    }

    .blockLeft blockquote:first-child {
      margin-top: 60px;
    }
  
    .privacy .content, .contact .content, .imprint .content {
      max-width: 100%;
    }
    
    .wrapperSqueeze {
      padding-top: 0px;
    }
    
    .wrapper {
      min-height: 36px !important;
    }
  
    .headerImage {
      margin-top: calc(var(--block-padding) *2);
    }
  
    .grid .post {
      display: flex;
      width: 100%;
    }
  
    .grid .post .image .box .loading {
      width: 140px !important;
      height: 150px;
    }
  
    .grid .post {
      margin-bottom: var(--block-padding);
    }
  
    .list .post {
      align-items: top;
      justify-content: flex-end;
      flex-direction: column-reverse;
    }
  
    .list .post .image .box .loading {
      width: 100%;
      height: 460px;
    }
  
    .blockText h2 {
      font-size: 26px;
      line-height: 36px;
    }
  
    .list .post .image .box {
      margin-top: 0px;
    }
  
    .list .post .text, .list .post .image {
      width: 100%;
      padding-left: 0px;
    }
  
    .list .post .text h1 {
      padding-top: 28px;
    }
  
    .post .text h1 {
      font-size: 42px;
      line-height: 42px;
    }

    .blockLeft .image .loading {
      width: 100% !important;
      height: 360px;
      margin-top: 26px;
      margin-bottom: 20px;
      margin-right: 0px;
    }

    .blockText .image .loading  {
      width: 100% !important;
      height: 360px;
      margin-top: 13px;
      margin-bottom: 10px;
      margin-right: 0px;
    }

    .contact .blockText {
      margin-top: 0px;
    }
  
    .contactBanner {
      text-align: left;
      padding-top: 0px;
    }
  
    .contactBanner .content {
      flex-direction: column-reverse;
      align-items: flex-start;
      width: 100%;
      padding-bottom: 0px;
    }
  
    .contactBanner .contactButton a img {
      margin-left: 0;
      margin-right: 6px;
      transform: none;
    }
  
    .contactButton .authorBubble .loading {
      transform: none;
      margin-right: 6px;
    }
  
    .contactBanner .content .text {
      width: 100%;
      font-size: 16px;
      line-height: 26px;
      padding-bottom: 0;
    }
  
    .contactBanner .authorBubble {
      margin: var(--block-padding) 0 calc(var(--block-padding)/2) 0;
    }
  
    .contactBanner .authorBubble .authorPic  {
      width: 70px;
      height: 70px;
      border: solid 5px rgba(255,255,255,0.1);
    }
  
    .footer {
    }
  
    .services .anchor {
      transform: translate(0.4em, 0px);
    }

    .contactBanner, .footer {
      animation-delay: 0s;
    }
  }
